<template>
  <v-flex sm7>
    <v-card flat tile class="banner_guest" :img="'/static/background_image.png'"></v-card>
  </v-flex>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    background_img: String,
  },
};
</script>
<style scoped>
.filter_card {
  background-color: rgba(4, 116, 172, 0.509) !important;
}
.banner_guest {
  min-height: 100vh;
  min-width: 100%;
  height: 100vh;
  width: 100%;
}
</style>